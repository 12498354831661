// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/aulon/git/praha.knowit.no/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-favorites-js": () => import("/home/aulon/git/praha.knowit.no/src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("/home/aulon/git/praha.knowit.no/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/home/aulon/git/praha.knowit.no/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */)
}

